import { useQueryParams } from '../../../utils/queryParams';
import { useMutation } from 'react-apollo';
import { CONFIRM_GOOGLE_OAUTH_MUTATION } from '../hooks/queries';
import { useEffect } from 'react';
import { ConfirmGoogleOauthMutation } from '../hooks/@types/ConfirmGoogleOauthMutation';
import { setAuthToken } from '../utils/setAuthToken';
import { useSetUserLocale } from '../../../utils/Data/Admin/Locale/useSetUserLocale';
import { afterLoginActions } from '../utils/afterLoginActions';
import { log } from 'cf-common/src/logger';
import { useHistory } from 'react-router-dom';
import { redirectToAwesomePanel } from '@utils/AuthFlow/redirectToAwesomePanel';
import {
  isSwitchFromAwesomePanelSession,
  SWITCH_FROM_AWESOME_PANEL_API_QUERY_PARAM_NAME,
} from '@utils/switchFromAwesomePanel';

export const GoogleOAuthHandler = () => {
  const { code } = useQueryParams<{ code: string }>();
  const setUserLocale = useSetUserLocale();
  const { error, state } = useQueryParams<{ error?: string; state?: string }>();
  const history = useHistory();

  const [confirmGoogleOauth] = useMutation<ConfirmGoogleOauthMutation>(
    CONFIRM_GOOGLE_OAUTH_MUTATION,
  );

  useEffect(() => {
    if (error) {
      history.push(`/sign-in?error=${error}`);
    }
  }, [error, history]);

  useEffect(() => {
    if (!code) {
      return;
    }

    const handleOauth = async () => {
      const query = [];
      if (isSwitchFromAwesomePanelSession()) {
        query.push({
          key: SWITCH_FROM_AWESOME_PANEL_API_QUERY_PARAM_NAME,
          value: 'true',
        });
      }

      try {
        const { data } = await confirmGoogleOauth({
          variables: {
            code,
            query,
          },
        });

        if (
          data?.confirmGoogleOauth.redirect_to_awesome_panel &&
          data?.confirmGoogleOauth.awesome_panel_auth_token
        ) {
          redirectToAwesomePanel(
            data.confirmGoogleOauth.awesome_panel_auth_token,
          );
          return;
        }
        if (data?.confirmGoogleOauth.access_token) {
          await setAuthToken(data.confirmGoogleOauth.access_token);
          await setUserLocale();
          await afterLoginActions(state);
        } else {
          log.error({
            msg: 'google-auth',
            error: data?.confirmGoogleOauth.error,
          });
          throw new Error();
        }
      } catch (error) {
        log.error({
          msg: 'google-auth',
          error,
        });
        history.push('/sign-in?error=something_went_wrong');
      }
    };

    handleOauth();
  }, [code, state, confirmGoogleOauth, setUserLocale, history]);

  return null;
};
